import React from 'react'
import { graphql } from 'gatsby'
import { Container, Box, Heading, Divider } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Reveal from '@solid-ui-components/Reveal'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes, normalizeContentfulUseCaseNodes } from '@blocks-helpers'
import styles from './_styles'

import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@theme-ui/mdx'
import components from '@components/Mdx'

const PrivacyPolicy = props => {
  const { allBlockContent, allContentfulLegal } = props.data
  const templateContent = normalizeBlockContentNodes(allBlockContent?.nodes)
  const contentfulContent = allContentfulLegal?.nodes[0]

  return (
    <Layout {...props}>
      <Seo title='Privacy Policy' description='Privacy Policy - Sheet Gurus SMS' />
      {/* Modals */}
      <ModalWithTabs content={templateContent['authentication']} reverse />
      <ModalWithTabs content={templateContent['contact']} />
      <ModalSimple content={templateContent['advertisement']} />
      {/* Blocks */}
      <Header content={templateContent['header']} />
      <Container variant='full' sx={styles.heroContainer}>

      <Container variant='wide'>
        <Box
          sx={{
            flexBasis: `2/5`,
            textAlign: [`center`, null, null, `left`]
          }}
        >

          <Reveal effect='fadeInDown'>
            <Heading
              sx={styles.heading}
              variant="h1"
              as="h1"
            >
              {contentfulContent.title}
            </Heading>
            <Divider space={5} sx={styles.divider} />
            <MDXProvider components={components}>
              <MDXRenderer>{contentfulContent.body.childMdx.body}</MDXRenderer>
            </MDXProvider>
          </Reveal>
        </Box>
      </Container>
      </Container>
      <Footer content={templateContent['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpagePrivacyPolicyContent {
    allBlockContent(
      filter: { page: { in: ["shared"] } }
    ) {
      nodes {
        ...BlockContent
      } 
    }
    allContentfulLegal(filter: {slug: {eq: "privacy-policy"}}) {
      nodes {
        identifier: slug
        page: slug
        title
        body {
            childMdx {
              body
            }
        }
      }
    }
  }
`

export default PrivacyPolicy
